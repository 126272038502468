/**
 * Loading Service
 */

import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isBrowser: boolean;
  loading: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  addLoading() {
    if (this.isBrowser) {
      this.loading = true;
    }
  }

  removeLoading() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
