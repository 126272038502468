export const Constants = {

    // Auth
    login: '/auth/login',
    forgotpassword: '/auth/forgotpassword/',
    resetpassword: '/auth/resetpassword',
    changepassword: '/auth/changepassword',
    verifyemail: '/auth/verifyemail/',
    resendverifymail: '/auth/resendlink/',
    adminpassword: '/auth/adminpassword',
    g2flogin: '/auth/g2flogin',

    // User
    signup: '/user/signup',
    getallusers: '/user/getall',
    updatetheme: '/user/updatetheme',
    editprofilepicture: '/user/editprofilepicture',
    getuser: '/user/getuser',
    getuserdetails: '/user/getuserbyid/',
    getuserdealsdetails: '/user/getuserdeals/',
    updatebyid: '/user/updatebyid',
    updatebyuserid: '/user/update',

    getg2f: '/user/getg2f',
    enableg2f: '/user/enableg2f',
    disableg2f: '/user/disableg2f',
    verifyg2f: '/user/verifyg2f',

    // Dashboard
    getdashboard: '/dashboard/getprofitsalesdetails',

    // Subscribe
    subscribe: '/subscribe/create/',

    // Crypto
    getbalance: '/crypto/getallbalance',

    // Team
    addteam: '/team/add',
    updateteam: '/team/update',
    updatenotes: '/team/updatenotes',
    updatelogo: '/team/updatelogo',
    updateterms: '/team/updateterms',
    updatemember: '/team/updatemember',
    deletemember: '/team/deletemember',
    getmyteam: '/team/getbyid/',
    getteambyuserid: '/team/getteambyuserid',
    addmember: '/team/addmember',
    disableuser: '/team/disableuser/',
    enableuser: '/team/enableuser/',

    // Seller
    addseller: '/seller/add',
    getsellerbyteamid: '/seller/getallbyteamid',
    updateseller: '/seller/update',
    removeseller: '/seller/remove',
    deletemanysellers: '/seller/deletemany',

    // Gear
    addgear: '/gear/add',
    updategear: '/gear/update',
    getgearsbyseller: '/gear/getallbysellerid',
    getallgears: '/gear/getall',
    getroigears: '/gear/roigears',
    getavailable: '/gear/getavailable',
    deletemanygears: '/gear/deletemany',
    updategeardotd: '/gear/updatedotd',
    updategearview: '/gear/updateview',

    // Service
    addservice: '/service/add',
    updateservice: '/service/update',
    getservicebyseller: '/service/getallbysellerid',
    getallservices: '/service/getall',
    deletemanyservices: '/service/deletemany',

    // Template
    createtemplate: '/template/createtemplate',
    getalltemplates: '/template/getall',
    gettemplatebyid: '/template/getbyid/',
    updatetemplate: '/template/update',
    gettemplatebyuserid: '/template/getbyuserid',
    gettemplatebyteamid: '/template/getsharedteamtemplates',
    removetemplate: '/template/remove/',
    deletetemplatemany: '/template/deletemany',
    teamaccess: '/template/teamaccess',

    // Invoice 
    createinvoice: '/invoice/createinvoice',
    getinvoicebyid: '/invoice/getbyid/',
    getreportbyid: '/invoice/getreport/',
    sendinvoice: '/invoice/sendinvoice',
    getinvoicebyuserid: '/invoice/getbyuserid',
    getinvoicebyteamid: '/invoice/getbyteamid',
    getinvoicebypassword: '/invoice/getbyid',
    updateinvoice: '/invoice/update',
    editlogo: '/invoice/editlogo',
    updatevendorinvoice: '/invoice/updatevendorinvoice',
    downloadvendorinvoice: '/invoice/downloadvendorinvoice/',
    deletevendorinvoice: '/invoice/deletevendorinvoice/',
    archiveinvoices: '/invoice/archivemany',
    unarchiveinvoices: '/invoice/unarchivemany',
    invalidinvoices: '/invoice/invalidmany',
    updatepaydetails: '/invoice/updatepaymentstatus',
    downloadinvoice: '/invoice/pdf/',
    isvalidinvoice: '/invoice/isvalidinvoice/',

    // Deal
    createdeal: '/deal/create',
    updatedeal: '/deal/update',
    getdealsbytemid: '/deal/getbytemid',

    // Permission
    createPermission: '/permission/add',
    updatepermission: '/permission/update',
    getallpermissionbyteamid: '/permission/getallbyteamid',
    deleterole: '/permission/deleterole',
    mapuserpermission: '/permission/mapuserpermission',
    getpermissionbyid: '/permission/getbyid/',

    // Order
    createorder: '/order/create',
    getbyorder: '/order/getbyorder',
    getallorders: '/order/getallorders',
    deletemanyorder: '/order/deletemany',
    voidmanyorder: '/order/voidmany',
    archivemanyorder: '/order/archivemany',
    setorderstatus: '/order/setorderstatus',
    customordermail: '/order/customordermail',

    // Walletaddress 
    addwalletaddress: '/walletaddress/add',
    removewalletaddress: '/walletaddress/remove',

    // deposit
    adddeposit: '/deposit/add',
    removedeposit: '/deposit/remove',
    depositcrypto: '/deposit/depositcrypto',

    // shipment
    addshipment: '/shipment/add',
    getallshipment: '/shipment/getall',
    getshipmentbyid: '/shipment/getbyid/',
    removeshipment: '/shipment/remove',
    updateshipment: '/shipment/update',
    updateshipmentstatus: '/shipment/updatestatus',
    updateshipmentnotes: '/shipment/updatenotes',
    getbyinvoicenumber: '/shipment/getbyinvoicenumber/',

    // keys
    addkey: '/keys/add',
    removekey: '/keys/remove/',
    getkeys: '/keys/getlast4',
    getcryptobalance: '/keys/getbalance',

    // currency
    UpdateCurrency: '/user/updatecurrency',
    rateCurrency: '/user/getrate/',

    // Hosting Directory
    getAll: '/host/getall',
    getDetails: '/host/getdetails/',
    updatepaymentstatus: '/host/updatepaymentstatus',
    hostviewupdate: '/host/hostviewupdate',
    hostdelete: '/host/delete'
}