import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../shared/constants/constants';
import { environment } from '../../environments/environment.prod';

@Injectable({
    providedIn: 'root',
})

export class KeyService {
    private baseUrl = environment.url;

    constructor(private http: HttpService) { }

    AddKey(value) {
        return this.http.post(this.baseUrl + Constants.addkey, value);
    }

    GetBalance() {
        return this.http.get(this.baseUrl + Constants.getcryptobalance);
    }

    GetAllKeys() {
        return this.http.get(this.baseUrl + Constants.getkeys);
    }

    RemoveKey(value) {
        return this.http.delete(this.baseUrl + Constants.removekey + value);
    }

    GetBTCValue() {
        return this.http.getpublic('https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=BTC')
    }

    GetCryptoValue(crpto) {
        return this.http.getpublic('https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=' + crpto)
    }

    GetCryptoCurrencies() {
        return this.http.getpublic(' https://api.bittrex.com/v3/currencies')
    }

    getLoc(data) {
        return this.http.getpublic(data)
    }
}