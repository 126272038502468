import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './services/seo.service';
import { LoadingService } from './services/loading.service';
import { KeyService } from './services/key.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  title = 'CrypBooks';

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    public loadingService: LoadingService,
    private keyService: KeyService
  ) {
    // router.events.subscribe(event => {
    //   if (event instanceof RoutesRecognized) {
    //     let current_route = event.state.root.firstChild;
    //     console.log(current_route)
    //     this.title = current_route?.data.title;
    //     if (this.title == undefined) {
    //       alert('Please set page title for the routing')
    //     }
    //     else {
    //       titleService.setTitle(this.title);
    //     }

    //   }
    // });
  }

  setDocTitle(title: string) {
    this.titleService.setTitle(title);
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position, 'positionpositionpositionpositionpositionposition')
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        // this.callApi(longitude, latitude);
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  callApi(Longitude: number, Latitude: number) {
    // console.log(Longitude, Latitude)
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`
    //Call API
    this.keyService.getLoc(url).subscribe(res => {
      console.log(res, 'ffffffffffffffffffffffffff')
    })
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.seoService.updateTitle(event.title || this.title);

      if (event.ogurl) {
        this.seoService.updateOgUrl(event.ogurl);
      }

      if (event.title) {
        this.seoService.updateOgTitle(event.title || this.title);
      }

      if (event.description) {
        this.seoService.updateOgDescription(event.description);
      }

      if (event.ogimg) {
        this.seoService.updateOgImage(event.ogimg);
      }

      if (event.site_name) {
        this.seoService.updateOgSiteName(event.site_name);
      }

      if (event.keywords) {
        this.seoService.UpdateKeywords(event.keywords);
      }

      if (event.canonicalUrl) {
        this.seoService.setCanonicalURL(event.canonicalUrl);
      }
    });
    // this.getLocation();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if ((event['url']) && !(event['url'].includes('auth'))) {
          this.loadingService.addLoading();
        }
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loadingService.removeLoading();
      }
    });
  }
}
