import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthContentComponent } from './auth-content/auth-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from './nz-zorro/ng-zorro-antd.module';
import { PasswordComponent } from './password/password.component';
import { GreetingsComponent } from './greetings/greetings.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { AddressComponent } from './address/address.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxEditorModule } from 'ngx-editor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MailComponent } from './mail/mail.component';
import { SelectTemplatesComponent } from './select-templates/select-templates.component';
import { SafePipe } from './pipes/safe.pipe';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TableLoaderComponent } from './loaders/table-loader/table-loader.component';
import { DealsCardComponent } from './components/deals-card/deals-card.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FeatherIconsModule } from './feather-icons/feather-icons.module';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        AuthContentComponent,
        PasswordComponent,
        GreetingsComponent,
        AddressComponent,
        SidenavComponent,
        MailComponent,
        SelectTemplatesComponent,
        SafePipe,
        TableLoaderComponent,
        DealsCardComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        NgZorroAntdModule,
        AutoSizeInputModule,
        ClipboardModule,
        TextareaAutosizeModule,
        DragDropModule,
        NgxEditorModule,
        AngularEditorModule,
        Ng2TelInputModule,
        HighchartsChartModule,
        FeatherIconsModule
    ],
    exports: [
        SidenavComponent,
        PasswordComponent,
        PageNotFoundComponent,
        AuthContentComponent,
        GreetingsComponent,
        AddressComponent,
        MailComponent,
        TableLoaderComponent,
        DealsCardComponent,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        NgZorroAntdModule,
        AutoSizeInputModule,
        ClipboardModule,
        TextareaAutosizeModule,
        DragDropModule,
        NgxEditorModule,
        AngularEditorModule,
        SafePipe,
        Ng2TelInputModule,
        HighchartsChartModule,
        FeatherIconsModule
    ],
})
export class SharedModule { }
