import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NzMessageService } from "ng-zorro-antd/message";

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    headers: HttpHeaders = new HttpHeaders();
    fileHeaders: HttpHeaders = new HttpHeaders();

    constructor(private http: HttpClient,
        private message: NzMessageService,
        private router: Router) { }

    SetHeader() {
        const token = localStorage.getItem('token');
        if (token) {
            this.headers = new HttpHeaders({
                Authorization: token,
            });
        }
    }

    SetFileHeader() {
        const token = localStorage.getItem('token');
        if (token) {
            this.fileHeaders = new HttpHeaders({
                Authorization: token,
                // 'Content-Type': 'multipart/form-data',
            });
        }
    }

    handleErrors = (err) => {
        if (err.status === 401) {
            this.message.remove();
            this.message.error('Unauthorized Access');
            localStorage.clear();
            this.router.navigate(['/auth/login']);
        } else {
            // console.log(err);
            if (err?.error?.inputValid) err.error.message = err.error.errors[0];
            return throwError(err);
        }
    };

    get(url, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetHeader();
        return this.http
            .get(url, { headers: this.headers, params })
            .pipe(catchError(this.handleErrors));
    }

    getfile(url, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetHeader();
        return this.http.get(url, { headers: this.headers, params, responseType: 'blob' as 'json' })
            .pipe(catchError(this.handleErrors));
    }

    getpublic(url, params: HttpParams = new HttpParams(), headers?): Observable<any> {
        return this.http
            .get(url, { params, headers })
            .pipe(catchError(this.handleErrors));
    }

    post(url: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetHeader();
        // console.log(this.headers);
        return this.http
            .post(url, body, { headers: this.headers, params })
            .pipe(catchError(this.handleErrors));
    }

    postpublic(url: string, body: Object = {}, params: HttpParams = new HttpParams(), headers?): Observable<any> {
        return this.http
            .post(url, body, { headers, params })
            .pipe(catchError(this.handleErrors));
    }

    put(url: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetHeader();
        return this.http.put(url, body, { headers: this.headers, params }).pipe(catchError(this.handleErrors));
    }

    patch(url: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetHeader();
        return this.http.patch(url, body, { headers: this.headers, params }).pipe(catchError(this.handleErrors));
    }

    delete(url: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http
            .delete(url, { headers: this.headers, params })
            .pipe(catchError(this.handleErrors));
    }

    FileUpload(url: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
        this.SetFileHeader();
        return this.http.post(url, body, { headers: this.fileHeaders, params })
            .pipe(catchError(this.handleErrors));
    }

}
